import { createContext, useEffect, useState } from "react";

export const GeneralContext = createContext()

export default function GeneralProvider({children}){
    const apiURL = "https://hale-backend.onrender.com"
    const [messages, setMessages] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(function(){
        async function getAllMessages(){
            setLoading(true)
            const res = await fetch(`${apiURL}/message`)
            const data = await res.json()
            console.log(data); 
            setMessages(data.data.messages)    
            setLoading(false)       
        }
        getAllMessages()
    },[])

    return(
        <GeneralContext.Provider value={{
            messages,
            setMessages,
            loading
        }}>
            {children}
        </GeneralContext.Provider>
    )
}