import { useContext } from "react";
import Message from "../Message/Message";
import "./MessagesList.css";
import { GeneralContext } from "../../../hooks/GeneralContext";
export default function MessagesList() {
  const { messages, loading } = useContext(GeneralContext);
  return (
    <div className="messages-list">
      {!loading ? (
        messages.map((message, index) => (
          <Message message={message} key={index} index={index} />
        ))
      ) : (
        <div className="loader-container">
          <img src="https://i.postimg.cc/s25BSHfc/banner-02.jpg" alt="" />
        </div>
      )}
      {!loading && messages.length === 0 &&(<div className="no-messages">
        <h1>There are no messages</h1>
      </div>)}
    </div>
  );
}
