import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Messages from "./pages/Messages";
import MainHeader from "./components/MainHeader/MainHeader";
import GeneralProvider from "./hooks/GeneralContext";
import OpenMessage from "./components/Messages/OpenMessage/OpenMessage";

export default function App() {
  return (
    <GeneralProvider>
      <BrowserRouter>
        <MainHeader />
        <Routes>
          <Route path="/" element={<Messages />} />
          <Route path="/messages/:id" element={<OpenMessage />} />
        </Routes>
      </BrowserRouter>
    </GeneralProvider>
  );
}
