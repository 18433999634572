import { useParams } from "react-router-dom";
import "./OpenMessage.css";
import { useContext } from "react";
import { GeneralContext } from "./../../../hooks/GeneralContext";
export default function OpenMessage() {
  const { messages } = useContext(GeneralContext);
  console.log(messages);

  const { id } = useParams();

  return (
    <div className="open-message">
      {messages.length > 0 ? (
        <>
          <h1>Message from {messages[id].name ? messages[id].name : ""}</h1>
          <hr />
          <h3>
            Email:{" "}
            <a href={`mailto:${messages[id].email}`}>{messages[id].email}</a>
          </h3>
          <hr />
          <h5>Message:-</h5>
          <p>{messages[id].message}</p>
        </>
      ) : (
        <div className="loader-container">
          <img src="https://i.postimg.cc/s25BSHfc/banner-02.jpg" alt="" />
        </div>
      )}
    </div>
  );
}
